@import "@Static/css/common";

.teamSectionContainer {
    position: relative;
    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
}

.teamAnchor {
    transform: translateY(-120px);
}

.dotMatrix {
    circle {
        fill: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    }
    position: absolute;
    top: 5rem;
    left: 4rem;
}

.teamSectionSubContainer {
    margin: auto;
    padding: 40px 20px;
    max-width: 1480px;
}

.title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
    color: rgb(var(--template-page-heading-text-host-theme, 34, 34, 34));
}

.carouselContainer {
    cursor: grab;
}

.carouselContainer > div > ul {
    margin: auto;
}

.customDot {
    margin: 1rem 3px;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 28px;
    }
}
