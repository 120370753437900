@import "@Static/css/common";

.anchorTestimonial {
    position: absolute;
    transform: translateY(-120px);
}
.root {
    max-width: 1480px;
    margin: auto;
}

.title {
    text-align: center;
    color: rgb(var(--template-page-heading-text-host-theme, 34, 34, 34));
    font-size: 40px;
    font-weight: 650;
}

.carouselContainer {
    position: relative;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.carousel {
    z-index: 1;
}

.dotMatrix {
    circle {
        fill: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    }
    position: absolute;
    left: 16rem;
    bottom: 1rem;
    z-index: 0;
}

@media only screen and (max-width: 1400px) {
    .dotMatrix {
        left: 10rem;
    }
}

@media only screen and (max-width: 1200px) {
    .dotMatrix {
        left: 7rem;
    }
}

@media only screen and (max-width: 1050px) {
    .dotMatrix {
        left: 2rem;
    }
}

@media only screen and (max-width: 920px) {
    .dotMatrix {
        left: 0;
    }
}

@media only screen and (max-width: 768px) {
    .testimonialHeader {
        font-size: 28px;
    }
}
