@import "@Static/css/common";

.sectionTitle {
    font-size: 40px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    // Some themes have multiple primary colors
    // This is to account for that

    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
    color: rgb(var(--template-page-body-text-host-theme, 34, 34, 34));
}
.images {
    width: 100%;
    margin-top: var(--host-page-navbar-height, 62px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.titleText {
    font-size: medium;
    font-weight: bold;
    margin: 15px;
}

.coverImage {
    width: 100%;
    height: 25vw;
    position: relative;
    background-color: #eee;
}

.profilePic {
    width: 11.5vw;
    height: 11.5vw;
    border-radius: 1000px;
    border: 4px solid #ffffff;
    margin-bottom: -8%;
    transform: translate(0%, -80%);
}

.nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.placeholderContainer {
    display: flex;
    border-radius: 8px;
    padding: 10px;
    aspect-ratio: 4/3;

    min-width: 250px;
    width: 40vw;
    max-width: 600px;

    min-height: #{250 * 9/16}px;
    height: #{40 * 9/16}vw;
    max-height: #{600 * 9/16}px;

    background-color: #f2f2f4;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    color: #555;
    margin-bottom: 24px;
}
.nameText {
    font-size: 40px;
    text-align: center;
    width: 100%;
    word-break: break-word;
    font-weight: 600;
    line-height: initial;
    margin: 0;
}

.bioText {
    font-size: 28px;
    text-align: center;
    width: 100%;
    max-width: 1000px;
    padding: 0px 2px 2px;
    overflow-wrap: break-word;
}
.videoContainer {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2.5%;
    div {
        min-width: 280px;
        aspect-ratio: 16/9;
        height: unset !important;
    }
}
@keyframes loadAnimation {
    from {
        left: -150px;
    }
    to {
        left: 100%;
    }
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 2px solid #e4e8f0;
    background-color: rgb(
        var(--template-block-background-host-theme, 255, 255, 255)
    );
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));

    align-items: center;
    min-width: 40%;
    width: min-content;
    max-width: 80%;
    margin: 0 2rem 2rem;
    padding: 18px;
    font-size: 20px;
    font-weight: 200;
    overflow-wrap: break-word;
    border-radius: 8px;
    word-break: break-word;

    a {
        color: rgb(var(--template-page-body-text-host-theme, 34, 34, 34));
    }
}
.getInTouchContainer {
    margin: 0 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 40%;
    max-width: 80%;
    border-radius: 8px;
    padding: 18px;
    text-align: center;
    z-index: 20;
    border: 2px solid #e4e8f0;
    background-color: rgb(
        var(--template-block-background-host-theme, 255, 255, 255)
    );
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
}
.ctaButton {
    border-radius: 100px;
    font-size: 20px;
    font-weight: 500;
    margin: 24px 0 0;
    letter-spacing: 1px;
    padding: 20px 8px;

    white-space: nowrap;
    padding: 12px 44px;
    width: fit-content;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.15s ease all;
    color: #fff;
    cursor: pointer;

    background-color: rgb(var(--template-primary-color-host-theme, 255, 91, 0));

    &:hover {
        transform: scaleX(1.02) scaleY(1.08);
        filter: brightness(1.1);
    }
}

.getInTouchText {
    font-size: 20px;
}
.moreCta {
    max-height: 1.5rem;
    width: 10rem;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    &:hover {
        text-decoration-line: underline;
    }

    align-self: flex-start;
    cursor: pointer;

    &,
    & .moreCtaArrow * {
        color: rgb(var(--template-primary-color-host-theme, 34, 34, 34));
    }
}

@media only screen and (max-width: 768px) {
    .profilePic {
        width: 20vw;
        height: 20vw;
        border: 3px solid #ffffff;
        transform: translate(0%, -60%);
    }
    .nameContainer {
        width: 90%;
    }
    .nameText {
        font-size: 20px;
    }
    .bioText {
        font-size: 14px;
    }
    .descriptionContainer {
        width: 90%;
        font-size: 14px;
        display: block;
    }
    .getInTouchContainer {
        width: 90%;
    }
    .getInTouchText {
        font-size: 14px;
    }
    .coverImage {
        font-size: 1.2em;
    }
    .placeholderContainer {
        font-size: 16px;
        width: 80vw;
        height: #{80 * 9/16}vw;
    }
    .ctaButton {
        font-size: 16px;
        padding: 12px 24px;
    }
}
