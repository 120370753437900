@import "@Static/css/common";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

    transition: 0.2s ease all;

    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}
.imageContainer {
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;
    position: relative;
}
.eventImage {
    width: 100%;
    max-height: 228px;
    border-radius: 4px 4px 0 0;
    align-items: flex-start;
    place-content: flex-start;
    align-self: flex-start;
    object-fit: contain;
}
.eventDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    margin: 10px;
    text-align: center;
}
.eventCardContainer {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 400px;
    align-items: center;
    display: flex;
    border-radius: 4px;
    margin: 2rem;
    padding-bottom: 2%;
    justify-content: center;
    position: relative;
    flex-direction: column;
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    background-color: rgb(
        var(--template-block-background-host-theme, 255, 255, 255)
    );

    cursor: pointer;
    transition: 0.3s ease all;
    &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
    }
}
.bookNowButton {
    min-width: 50%;
    max-width: 80%;
    min-height: 50px;
    height: min-content;
    width: auto;
    border-radius: 26px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    top: -14px;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(var(--template-primary-color-host-theme, 255, 91, 0));

    transition: 0.2s ease all;
    &:hover {
        filter: brightness(1.2);
    }

    border: 1.5px solid;
    border-color: transparent !important;
}
.stickyBookCta {
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.cardIconSet {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    padding: 5px;
}
.eventTagView {
    background-color: transparent;
    position: absolute;
    top: 10px;
    left: -10px;
    width: auto;
    display: flex;
    flex-direction: column;
}

.rectangle {
    display: flex;
    align-self: start;
    padding: 5px 10px;
    justify-content: center;
    background-color: #000000;
}

.triangleCorner {
    width: 0;
    height: 0;
    border-top: 10px solid black;
    border-left: 10px solid transparent;
}

.tagTitleStyle {
    color: white;
    font-size: 12px;
}
.iconStyle {
    width: 39px;
    height: 39px;
    margin: 1%;
}
.short_description_text {
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
    width: 90%;
    text-align: center;
    margin: 2px 0 8px;
    font-weight: 600;

    color: rgb(var(--template-price-tag-host-theme, 251, 1, 132));

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.titleStyle {
    font-size: 16px;
    font-weight: 550;
    width: 90%;
    text-align: center;

    opacity: 0.9;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.leftContent {
    color: #ffffff;
    padding: 4px 12px;
    border-radius: 3px 0 0 3px;
    font-size: 14px;
    margin: 0 0 7px 0;
    position: relative;
    left: 5px;
}

@media only screen and (max-width: 768px) {
    .eventImage {
        width: 100%;
        max-height: 228px;
        max-width: 400px;
    }
    .eventCardContainer {
        max-width: 400px;
        width: 100%;
    }
    .iconStyle {
        width: 30px;
        height: 30px;
        margin: 1%;
    }
    .bookNowButton {
        top: -16px;
        min-height: 46px;
        font-size: 12px;
    }
    .short_description_text {
        font-size: 10px;
    }
    .titleStyle {
        font-size: 14px;
    }
    .eventDetail {
        height: 100%;
        margin: 20px;
    }
}
