@import "@Static/css/common";

.container {
    height: 400px;
    width: calc(100% - 4rem);
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    border: 2px solid #e4e8f0;
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    background-color: rgb(
        var(--template-block-background-host-theme, 255, 255, 255)
    );
    padding: 3px;
}

.authorContainer {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
}

.authorImage {
    border-radius: 8px;
    height: 3rem;
    width: 3rem;
    user-select: none;
}

.authorName {
    margin-left: 1rem;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    overflow: hidden;
}

.mediaContainer {
    width: 100%;
    height: 75%;
    position: relative;
    user-select: none;
}

.mediaOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 1000;
}

.video {
    iframe {
        border-radius: 8px;
    }
    &.empty {
        color: transparent;
        text-decoration: none;
    }
}

.imageContainer {
    display: flex;
    justify-content: center;
    background: linear-gradient(
        to right,
        getColorValue(1, (0, 0, 0)),
        getColorValue(2, (255, 255, 255))
    );
    border-radius: 8px;
}

.image {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.imageOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    &.imageOverlay {
        svg {
            circle {
                fill: getColorValue(1, (0, 0, 0));
            }
        }
    }
}

.quote {
    position: absolute;
    opacity: 0.3;
    path {
        fill: rgb(var(--template-primary-color-host-theme, 255, 91, 0));
    }
}

.textContainer {
    width: 100%;
    height: 75%;
    padding: 2rem;
}

.text {
    height: 75%;
    padding: 1rem;
    font-size: 14px;
    font-weight: 600;
    white-space: pre-wrap;
    word-break: break-word;
}

.readMore {
    padding: 0;
}

@media only screen and (max-width: 768px) {
    .container {
        width: calc(100% - 2rem);
    }

    .text {
        font-size: 12px;
    }
}
