.root {
    align-items: flex-start;
    display: flex;
    border-radius: 4px;
    margin: 50px 0 2rem;
    max-width: 95vw;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    cursor: pointer;
}

.cardImage {
    box-shadow: 0px 0px 15px #00000033;
    background-color: #f2f2f4;
    height: 300px;
    width: 350px;
    // aspect-ratio: 1;
    background-image: url("/static/images/default-image-placeholder.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &::after {
        counter-increment: previewCard;
        content: "Gallery Item " counter(previewCard);
        position: absolute;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
        background-color: #333c;
        color: #fff;
        backdrop-filter: blur(4px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        letter-spacing: -0.4px;
    }
}
.caption {
    margin: 16px 0 -6px;
    font-weight: 600;
    font-size: 16px;
}
