// WARNING: This stylesheet is being used in multiple files. Exercise caution while making changes.

// TODO - @aditya: Move this stylesheet inside the <template_name> folder directly and update imports accordingly.

@import "@Static/css/common";

.sectionTitle {
    font-size: 40px;
    color: getColorValue(3, (0, 0, 0));
}

.root {
    position: relative;
    overflow-x: hidden;
    max-width: 100vw;
    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
}

.hostIntro {
    padding-bottom: 2%;

    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
}
.headerTitle {
    text-align: center;
    font-size: 40px;
    font-weight: 650;
    color: rgb(var(--template-page-heading-text-host-theme, 0, 0, 0));
    margin: auto;
    width: 100%;
}

.eventHeader {
    @extend .headerTitle;
}
.loadingSpinner {
    margin-bottom: 3rem;
    transform: translateX(-30px);
}
.galleryHeader {
    @extend .headerTitle;
}
.eventContainer {
    display: flex;
    width: 100%;
    padding: 2%;
    padding-top: 2%;
    padding-bottom: 40px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1500px;
    margin: auto;

    color: rgb(var(--template-page-body-text-host-theme, 34, 34, 34));
    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
}
.galleryContainer {
    display: flex;
    width: 100%;
    padding: 2% 0;
    margin-bottom: -2%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(var(--template-page-text-color-host-theme, 34, 34, 34));
    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
}
.galleryShowMore {
    background-color: #fff;
    border-color: #ff5b00;
    color: #ff5b00;
}
.galleryShowMore:hover {
    background-color: #fff !important;
    border-color: #ff5b00;
    color: #ff5b00 !important;
}
.arrowGallery {
    border-radius: 50%;
    background-color: #e7e7e7;
    display: flex;
    position: absolute;
    justify-content: center;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
}
.eventCardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.galleryOuterDiv {
    width: 100%;
    overflow-x: hidden;
}
.galleryDiv {
    width: 100%;
}
.feedbackContainer::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 1;
}

@media only screen and (min-width: 769px) {
    .stickyContainer {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .stickyContainer {
        justify-content: center;
        align-items: center;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 20px;

        background-color: rgb(
            var(--template-page-background-host-theme, 255, 255, 255)
        );

        backdrop-filter: blur(2px);

        z-index: 500000;
    }
}

.spreadWordButton {
    width: 70%;
    height: 2rem;
    background-color: rgb(var(--template-primary-color-host-theme, 34, 34, 34));
    color: #ffffff !important;
    border-color: #00000000 !important;
    text-align: center;
    border-radius: 35px;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 600;
    margin: 5px;
    box-shadow: #3b3b3b;
    z-index: 1000;
    min-width: fit-content;
}
.spreadWordButton:focus {
    box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
}
.arrowStyleCarousal {
    color: white;
    display: flex;
    border-radius: 50%;
    margin: 0 12px;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    cursor: pointer;
    top: calc(50% - 50px);
    background-color: #0009;
}

.icon {
    width: 18px;
    height: 18px;
}
.continueCtaButton {
    margin-top: 30px;
    width: 100%;
}
.textOnPop {
    padding: 10px;
    opacity: 0.35;
    margin-top: 15px;
    font-size: 12px;
    text-align: center;
}

.previewSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    color: rgb(var(--template-page-body-text-host-theme, 34, 34, 34));
    background-color: rgb(
        var(--template-page-background-host-theme, 255, 255, 255)
    );
    max-width: 1400px;
    margin: auto;
    counter-reset: previewCard;
}

.eventCardPlaceholder {
    box-shadow: 0px 0px 15px #00000033;
    width: 400px;
    align-items: flex-start;
    display: flex;
    border-radius: 4px;
    margin: 50px auto 2rem;
    padding-bottom: 2%;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;

    background-color: #f2f2f4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leftAligned {
    left: -10px;
    position: absolute;
}
.rightAligned {
    right: -10px;
    position: absolute;
}

.anchorEventSection {
    transform: translateY(-250px);
}

.anchorGallery {
    position: absolute;
    transform: translateY(-120px);
}

.cardContainerSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (min-width: 769px) {
    .galleryC {
        &:not(.carouselEnabled) {
            display: flex;
            justify-content: center;
            align-content: space-between;
            margin: auto;
        }
    }
    .galleryOuterDiv {
        max-width: min(
            1400px,
            100vw - 100px
        ); // 100px = 2 * 50px (padding of the footer)
    }
}
@media only screen and (max-width: 768px) {
    .headerTitle {
        font-weight: 650;
        color: rgb(var(--template-page-heading-text-host-theme, 0, 0, 0));
        margin: auto;
        width: 100%;
        font-size: 28px;
        text-align: center;
    }
    .eventHeader {
        @extend .headerTitle;
    }
    .galleryHeader {
        @extend .headerTitle;
    }
    .feedbackContainer {
        width: 90%;
    }
    .galleryC {
        width: 90%;
        position: relative;
        height: auto;
        margin: auto;
    }
    .eventContainer {
        padding-bottom: 60px;
    }
    .eventCardPlaceholder {
        width: auto !important;
        max-width: 400px;
    }
}
