@import "@Static/css/common";

.teamMemberCardContainer {
    background: #aaa center no-repeat;
    background-size: cover;
    width: 17rem;
    height: 20.6rem;
    margin: 1rem auto 4rem auto;
    border-radius: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
}

.socialIconsContainer {
    display: flex;
    justify-content: flex-end;
}

.socialIcon {
    background-color: rgba(0, 0, 0, 0.75);
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 6px 6px 0 0;
    color: white;
    font-size: 1.3rem;
}

.detailsContainer {
    // 3.7rem for the description height
    // 1px for horizontalBar height
    transform: translateY(calc(3.2rem + 1px));
    bottom: 1rem;
    width: 85%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    line-height: 1.1;
    margin-left: auto;
    margin-right: auto;
}

.halfDetails {
    padding: 12px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 3,
            ),
            "bgc": (
                "key": 2,
            ),
        ),
        "schema1"
    );
}

.name {
    font-weight: 700;
    font-size: 1.1rem;
    padding-bottom: 0.4rem;
    @extend .plainScrollbar;
}

.heading {
    font-size: 0.6rem;
    font-weight: 600;
    height: 22.4px;
    overflow-wrap: break-word;
    word-break: keep-all;
}

.horizontalBar {
    height: 2px;
    width: 100%;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
            ),
        ),
        "schema1"
    );
    filter: brightness(2);
}

.description {
    height: 3.2rem;
    font-size: 0.6rem;
    color: #fff;

    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
            ),
        ),
        "schema1"
    );
    padding: 12px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-break: keep-all;
    @extend .plainScrollbar;
}
