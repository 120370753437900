.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 90vw;
    transition: 0.2s ease all;
    &:hover {
        transform: translateY(-4px) scale(1.02);
    }
}
.imageContainer {
    width: 380px;
    height: 380px;
    aspect-ratio: 1;
    border-radius: 4px;
    justify-content: flex-start;
    align-self: flex-start;
    max-width: 90vw;
    cursor: pointer;
}
.videoContainer {
    width: 380px;
    height: 380px;
    aspect-ratio: 1;
    border-radius: 4px;
    justify-content: flex-start;
    align-self: flex-start;
    cursor: pointer;
    max-width: 90vw;
    background-color: #000;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        top: 0;
        left: 0;
    }
}
.galleryCardContainer {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    align-items: center;
    display: flex;
    border-radius: 4px;
    margin: 10px;
    justify-content: center;
    position: relative;
    flex-direction: column;
    max-width: 90vw;
}
.cardIconSet {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    padding: 5px;
    cursor: pointer;
}
.iconStyle {
    width: 32px;
    height: 32px;
    margin: 1%;
}
.titleStyle {
    font-size: 20px;
    text-align: center;
    font-weight: 550;
    color: rgb(34, 34, 34);
    opacity: 0.85;
    overflow-wrap: break-word;
    width: 90%;
}
.caption {
    margin: 0 auto 16px;
    max-width: 380px;
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
}

@media only screen and (max-width: 768px) {
    .container {
        height: 90vw;
        width: 90vw;
        max-height: 340px;
        max-width: 340px;
        margin: 60px auto;
        aspect-ratio: 1;
    }
    .imageContainer {
        height: 90vw;
        width: 90vw;
        max-height: 340px;
        max-width: 340px;
        aspect-ratio: 1;
    }
    .videoContainer {
        aspect-ratio: 1;
        height: 90vw;
        width: 90vw;
        max-height: 340px;
        max-width: 340px;
    }
    .titleStyle {
        font-size: 12px;
        padding: 10px 0 10px 0;
    }
}
